import { FC } from 'react'
import StarRatingComponent from 'react-star-rating-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons'

export const StarsAndRatings: FC = () => {
  return (
    <span className={'mt-1'} style={{ lineHeight: 1 }}>
      <StarRatingComponent
        name={'rating'} /* name of the radio input, it is required */
        value={5} /* number of selected icon (`0` - none, `1` - first) */
        starCount={5} /* number of icons in rating, default `5` */
        starColor={'gold'} /* color of selected icons, default `#ffb400` */
        editing={false} /* is component available for editing, default `true` */
        renderStarIcon={(index, value) => {
          return (
            <span>
              {index <= value ? (
                <FontAwesomeIcon icon={faStar} color="gold" />
              ) : (
                <FontAwesomeIcon icon={faStar} color="gold" />
              )}
            </span>
          )
        }}
        renderStarIconHalf={() => {
          return (
            <span>
              <FontAwesomeIcon icon={faStarHalf} color="gold" />
            </span>
          )
        }}
      />
    </span>
  )
}

import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatPhoneNumber } from '../utils/string-utils'
import { Container } from '../Container'
import { LandingPageButton } from '../LandingPageButton'
import { GraySection } from '../GraySection'

type Props = {
  title: string
  description: string
  steps: { title: string; description: string; image: any }[]
  buttonText?: string
  phoneNumber?: string
}

export const ServicesHowItWorksSection: React.FC<Props> = ({ title, description, steps, buttonText, phoneNumber }) => {
  return (
    <GraySection className="text-center">
      <Container>
        <div>
          <h2 className="title">{title}</h2>
          <p className="description">{description}</p>
        </div>
        <div
          className="grid  md:grid-cols-3"
          style={{
            background: 'white',
            boxShadow:
              '0 13px 27px -5px rgba(50, 50, 93, .25), 0 8px 16px -8px rgba(0, 0, 0, .3), 0 -6px 16px -6px rgba(0, 0, 0, .025)',
          }}
        >
          {steps.map((value, index) => (
            <HowItWorksStep
              key={index}
              {...value}
              stepImage={<FontAwesomeIcon size={'4x'} color={'#ea3616'} icon={value.image} />}
            />
          ))}
        </div>
        {buttonText && (
          <div className="pt-5">
            <LandingPageButton buttonText={buttonText} />
          </div>
        )}
        {phoneNumber && (
          <div className={'mt-2'}>
            Or call us at <a href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a>
          </div>
        )}
      </Container>
    </GraySection>
  )
}

const HowItWorksStep: React.FC<{
  title: string
  description: string
  stepImage: React.ReactElement
}> = ({ title, description, stepImage }) => {
  return (
    <div className="py-4 flex flex-grow-1 flex-col h-100 text-center px-6" style={{ border: '1px solid #dddddd' }}>
      <div className="mx-auto">{stepImage}</div>
      <div className="mt-3">
        <h3>{title}</h3>
        <p className="description">{description}</p>
      </div>
    </div>
  )
}

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Container } from '../Container'

export const HowItWorksGreenCheckSection: React.FC<{
  first: GreenCheckHowItWorksProps
  second: GreenCheckHowItWorksProps
  third: GreenCheckHowItWorksProps
  title: string
}> = ({ first, second, third, title }) => {
  return (
    <section>
      <Container>
        <div className="text-center mb-5">
          <h2>{title}</h2>
        </div>
        <div className="grid md:grid-cols-3">
          <GreenCheckHowItWorks {...first} />
          <GreenCheckHowItWorks {...second} />
          <GreenCheckHowItWorks {...third} />
        </div>
      </Container>
    </section>
  )
}

type GreenCheckHowItWorksProps = {
  title: string
  subtitle: string
}
const GreenCheckHowItWorks: React.FC<GreenCheckHowItWorksProps> = ({ title, subtitle }) => {
  return (
    <div className="flex flex-row">
      <div className={'mr-2'}>
        <FontAwesomeIcon icon={faCheckCircle} style={{ fontWeight: 100 }} size={'2x'} color={'#14d90d'} />
      </div>
      <div>
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
    </div>
  )
}

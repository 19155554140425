import { SEMIndexPageTemplateProps } from './sem-old'
import * as changeCase from 'capital-case'

export const getTitleFromUTMS = ({ type, formalCity, content, make }: SEMIndexPageTemplateProps) => {
  switch (type) {
    case 'generic':
      return `The Best ${content} Mobile Brake Repair in ${formalCity} — We Come To You.`
    case 'comp':
      return `Don't Waste Time At A ${changeCase.capitalCase(content)} — We Come To You`
    case 'vehicle':
      return `The Best ${changeCase.capitalCase(make)} Brake Repair In ${formalCity}  — We Come To You`
    case 'vehicle-part':
      return `Our ${formalCity} Brake Techs Replace ${changeCase.capitalCase(make)} ${changeCase.capitalCase(
        content
      )} For Less!`
    case 'mm_generic':
      return `The Best Mobile Mechanics in ${formalCity} — We Come To You`
    case 'mm_vehicle-part':
      return `Our ${formalCity} Mobile Mechanics Replace ${
        make && changeCase.capitalCase(make)
      } ${changeCase.capitalCase(content)} For Less!`
    default:
      return `The Best Mobile Brake Repair in ${formalCity} — We Come To You.`
  }
}

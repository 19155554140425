import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons/faQuoteLeft'
import { Container } from './Container'
import { Carousel } from 'react-responsive-carousel'
import { StarsAndRatings } from './SEMPage/StarsAndRatings'
import { useRouter } from 'next/router'
import { capitalCase } from 'capital-case'

export const ReviewsV2Section: React.FC<{
  first: ReviewProps
  second: ReviewProps
  third: ReviewProps
  title: string
}> = ({ first, second, third, title }) => {
  return (
    <section>
      <Container>
        <div className="text-center pb-5">
          <h3>{title}</h3>
        </div>
        <div className="md:hidden">
          <Carousel showThumbs={false} showStatus={false} showArrows={false} autoPlay={true} infiniteLoop={true}>
            <V2Review {...first} />
            <V2Review {...second} />
            <V2Review {...third} />
          </Carousel>
        </div>
        <div className="hidden md:grid md:grid-cols-3 gap-4">
          <div>
            <V2Review {...first} />
          </div>
          <div>
            <V2Review {...second} />
          </div>
          <div>
            <V2Review {...third} />
          </div>
        </div>
      </Container>
    </section>
  )
}

type ReviewProps = {
  review: string
  reviewer: string
  from: string
}

export const V2Review: React.FC<ReviewProps> = ({ review, from, reviewer }) => {
  const { query } = useRouter()
  return (
    <div style={{ marginBottom: 40 }} className="flex flex-col h-100">
      <div className="flex flex-col items-center">
        <div
          className={'flex flex-col justify-center items-center'}
          style={{
            backgroundColor: '#ededed',
            width: '60px',
            height: '60px',
            border: '1px solid #dcdbd',
            borderRadius: 50,
            zIndex: 1,
          }}
        >
          <FontAwesomeIcon icon={faQuoteLeft} color={`#f04f23`} transform={'grow-9'} />
        </div>
      </div>
      <div
        style={{
          marginTop: '-30px',
          border: '1px solid #dcdbdb',
        }}
        className="flex flex-col px-4 py-5 h-100"
      >
        <p>{`"${review}"`}</p>
        <StarsAndRatings />
        <p className={'reviewer'}>
          {`- ${reviewer} `}
          {query && query.city && `, ${capitalCase(query.city as string)}`}
          {!query.city && from && `, ${from}`}
        </p>
      </div>
    </div>
  )
}

import * as React from 'react'
import Link from 'next/link'
import { Button } from './Button'
import { useQueryParam } from '../hooks/useQueryParam'
import classNames from 'classnames'

export function LandingPageButton(props: { buttonText: string; buttonProps?: any; className?: string }) {
  const query = useQueryParam()
  return (
    <Link href={{ pathname: `/get-quote/`, query }}>
      <a>
        <Button
          className={classNames(props.className)}
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 25,
            paddingRight: 25,
          }}
          {...props.buttonProps}
        >
          {props.buttonText}
        </Button>
      </a>
    </Link>
  )
}

import * as React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import {
  NoEmailScheduleServiceFormMultiStep,
  WizardScheduleServiceValues,
} from '../Forms/ScheduleServiceMultiStep/NoEmailScheduleServiceFormMultiStep'
import { Container } from '../Container'
import { StarsAndRatings } from './StarsAndRatings'
import { useQueryParam } from '../../hooks/useQueryParam'

type Props = {
  title: string
  subtitle?: string
  rating?: string
  reviewCount?: number
  formInitialProps?: WizardScheduleServiceValues
  sessionId?: string
}
import backgroundImage from '../../../public/img/nubrakes-van-house-800-num.jpg'

export const HeaderSection: React.FC<Props> = ({ title, subtitle, rating, reviewCount }) => {
  const query = useQueryParam()
  return (
    <section className="py-0 text-white">
      <div className="py-[6%] flex flex-col justify-center align-center relative w-full h-full min-h-[60vh]">
        <Image
          src={backgroundImage}
          placeholder="blur"
          layout={'fill'}
          objectFit="cover"
          objectPosition="bottom center"
          priority={true}
          alt="NuBrakes Mobile Repair Header Image"
        />
        <div
          id="overlay"
          style={{
            minHeight: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 1,
            backgroundColor: 'rgba(0,0,0,.75)',
          }}
        />
        <div style={{ zIndex: 2 }}>
          <Container className="text-center">
            <div className="grid lg:grid-cols-2 gap-4">
              <div className="text-center lg:text-left flex flex-col justify-center">
                <h1 className="text-2xl mt-4 md:mt-0 px-4 md:px-0 sm:text-2xl md:text-4xl text-white sm:mb-0">
                  {title}
                </h1>
                <p className="subheading text-white">
                  {subtitle
                    ? subtitle
                    : 'We save our customers 40% on average over shop prices — book affordable repairs without leaving your home.'}
                </p>
                <div
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.69)',
                    borderRadius: 6,
                  }}
                  className="p-3 flex-col flex-grow-0 md:flex hidden mr-auto text-center"
                >
                  <StarsAndRatings />
                  <p
                    style={{
                      color: '#cbd0d3',
                      lineHeight: 1,
                    }}
                  >
                    {`NuBrakes is rated ${rating}/5 by `}
                    <Link href={{ pathname: '/reviews/', query }} prefetch={false}>
                      <a
                        style={{
                          color: '#cbd0d3',
                          textDecoration: 'underline',
                        }}
                      >{`${reviewCount} brake repair customers`}</a>
                    </Link>
                  </p>
                </div>
              </div>
              <div className="mt-2">
                <div style={{ backgroundColor: 'white' }} className="flex flex-col p-4 pb-6 mt-0">
                  <NoEmailScheduleServiceFormMultiStep />
                </div>
                <div className="mt-3 block md:hidden">
                  <div
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.69)',
                      borderRadius: 6,
                    }}
                    className="inline-block px-3 py-2"
                  >
                    <StarsAndRatings />
                    <p
                      style={{
                        color: '#cbd0d3',
                        lineHeight: 1,
                      }}
                    >
                      {`NuBrakes is rated ${rating}/5 by `}
                      <Link href={{ pathname: '/reviews/', query }} prefetch={false}>
                        <a
                          style={{
                            color: '#cbd0d3',
                            textDecoration: 'underline',
                          }}
                        >{`${reviewCount} brake repair customers`}</a>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </section>
  )
}

export default HeaderSection

import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldCheck } from '@fortawesome/pro-duotone-svg-icons/faShieldCheck'
import { Container } from './Container'

type Props = {
  title: string
  description: string | React.ReactElement
}

export const HappinessGuaranteeSection: React.FC<Props> = ({ description, title }) => (
  <section className="text-center" style={{ backgroundColor: '#fff3f0' }}>
    <Container>
      <FontAwesomeIcon icon={faShieldCheck} color={`#f04f23`} size={'7x'} />
      <h3 className="py-4 title">{title}</h3>
      <p className="description">{description}</p>
    </Container>
  </section>
)
